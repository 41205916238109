<template>
  <tr>
    <th>
      <!-- Expand dropdown -->
    </th>
    <Search
      :value="searchFields.q || ''"
      @updateSearch="newValue => updateSearchParams('q', newValue)"
    />
    <th>
      <span>Status</span>
      <gl-form-select
        data-testid="search--states"
        @input="updateSearchParams('states', $event)"
        v-bind:value="searchFields.states"
        :options="[
          {
            text: 'All',
            value: ''
          },
          {
            text: 'Pending',
            value: 'PENDING'
          },
          {
            text: 'Starting',
            value: 'STARTING'
          },
          {
            text: 'In Progress',
            value: 'IN_PROGRESS'
          },
          {
            text: 'Stopping',
            value: 'STOPPING'
          },
          {
            text: 'Ended',
            value: 'COMPLETE'
          },
          {
            text: 'Error',
            value: 'ERROR'
          }
        ]"
      >
      </gl-form-select>
    </th>
    <th>
      <span>Health</span>
      <gl-form-select
        data-testid="search--health"
        @input="updateSearchParams('health', $event)"
        v-bind:value="searchFields.health"
        :options="[
          {
            text: 'All',
            value: ''
          },
          {
            text: 'Healthy',
            value: 'healthy'
          },
          {
            text: 'Alert',
            value: 'alert'
          }
        ]"
      >
      </gl-form-select>
    </th>
    <DateRangePicker
      :searchFields="searchFields"
      @changeSearchParams="onSearchParamsChange"
    />
    <th>
      <span class="noFilter">Type</span>
    </th>
    <th>
      <span class="noFilter">Transcode</span>
    </th>
    <th>
      <!-- Context menu -->
    </th>
  </tr>
</template>
<script>
import { GlFormSelect } from "@gitlab/ui";
import Search from "@/components/Table/SearchFields/Search";
import DateRangePicker from "@/components/Date/DateRangePicker.vue";

export default {
  name: "TableHead",
  components: {
    DateRangePicker,
    GlFormSelect,
    Search
  },
  props: {
    searchFields: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    onSearchParamsChange(value) {
      this.$emit("changeSearchParams", value);
    },
    updateSearchParams(column, value) {
      this.$emit("changeSearchParams", {
        ...this.searchFields,
        [column]: value
      });
    }
  }
};
</script>
<style scoped lang="scss">
.search {
  min-width: 10rem;
}

.date-input {
  width: 100%;
}

th > span {
  display: block;
  margin-bottom: 0.5rem;

  &.noFilter {
    margin-top: -20px;
  }
}

th > div {
  height: 22px;
}
</style>

<template>
  <DetailScreenComponent
    :fields-to-include="fieldNames"
    :go-to-list="goToList"
    :action-icons="actionIcons"
    :item="item"
  />
</template>
<script>
import { sourceApiClient } from "@/mixins/apiClients";
import DetailScreenComponent from "@/components/DetailScreenComponent.vue";
import { ITEMS_SOURCES } from "@/constants";
import userInfo from "@/mixins/userInfo.js";
import sourceActions from "@/mixins/sourceActions.js";
import streamProfileClient from "@/mixins/streamProfileClient.js";
import organizationApiClient from "@/mixins/organizationApiClient.js";
const { EDIT, YOUR } = ITEMS_SOURCES;
export default {
  components: { DetailScreenComponent },
  mixins: [
    organizationApiClient,
    sourceApiClient,
    streamProfileClient,
    userInfo,
    sourceActions
  ],
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route() {
      this.fetchData();
    }
  },
  methods: {
    handleKey(evt) {
      if (evt.code === "Escape") {
        this.goToList();
      }
      if (evt.code === "Space") {
        this.goToEdit();
      }
    },
    goToEdit() {
      this.$router.push(`${EDIT}/${this.item.id}`);
    },
    goToList() {
      this.$router.push(`${YOUR}`);
    },

    duplicate() {
      this.clone(this.item);
    },
    async arch() {
      await this.archive(this.item);
      await this.fetchData();
    },
    generateContribution() {
      this.createContribution(this.item.id);
    },
    preview() {
      if (this.item.previewStatus && this.item.previewStatus === "STARTED") {
        this.requestPreview(this.item, true);
        return;
      }
      this.requestPreview(this.item);
    },

    fetchData() {
      const id = this.$route.params.id;
      this.sourceApiClient.getById(id).then(source => {
        source.isEncrypted = source.isEncrypted === true ? "YES" : "NO";
        this.streamProfileClient
          .getById(source.streamProfileId)
          .then(streamProfile => {
            source.profileName = streamProfile.profileName;
            this.item = source;
          });
      });
    }
  },
  computed: {
    actionIcons() {
      return [
        {
          icon: "pencil",
          title: "Click or press the Space Bar to edit this Source",
          label: "Edit",
          id: "edit",
          action: this.goToEdit,
          disabled: false
        },
        {
          icon: "duplicate",
          action: this.duplicate,
          title: "Clone",
          label: "Clone",
          id: "clone",
          disabled: false
        },
        {
          icon: "archive",
          action: this.arch,
          title: "Archive",
          label: "Archive",
          id: "archive",
          disabled: this.item.status === "ARCHIVED"
        },
        {
          icon: "doc-new",
          tooltip: "Generate Contribution",
          action: this.generateContribution,
          id: "generateContribution",
          label: "Generate Contribution",
          disabled: false
        },
      ];
    }
  },
  data() {
    return {
      item: {},
      fieldNames: [
        "accessLevel",
        "availabilityZone",
        "billableStatus",
        "host",
        "isEncrypted",
        "maxBitrateBps",
        "maxLatencyMs",
        "organisationName",
        "port",
        "profileName",
        "protocol",
        "quotedPrice",
        "readyState",
        "sourceType",
        "streamId",
        "whitelistCidr"
      ]
    };
  }
};
</script>
